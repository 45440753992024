/* global rp$, window, document */

require('core-js/fn/array/from');

const inputSuggestions = require('public/js/components/input-suggestions');

const THE_SCRIPT = function() {
	const $onReady = require('public/js/lib/on-ready');

	$onReady({
		rp$,
		window,
		label: 'auto_suggest',
		fn: function() {
			// For each search input on the page, initialize search suggestions for that input
			const searchInputs = Array.from(document.querySelectorAll('.main-search-wrapper input'));
			searchInputs.forEach(function(inputEl) {
				inputSuggestions.initialize(inputEl);
			});
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
